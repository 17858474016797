/* eslint-disable @typescript-eslint/no-unused-vars */
import { SerializedData } from 'bernie-core';
import { Store } from 'bernie-plugin-mobx';
import { Logger, NOOP_LOGGER } from 'bernie-logger';
import { action, makeObservable, observable } from 'mobx';
import { SanityApiSource } from '../sources';
import { checkValue } from 'src/components/functions';
import SanityApiSourceSingleton from './helpers/sanityApiSourceSingleton';
import {
  Batch1TemplateService,
  Batch2TemplateService,
  Batch3TemplateService,
  VisitUsaTemplateService,
  MagazineTemplateService,
  AllStudioTemplateService,
  ExpediaLiveTemplateService,
  OceanHotelService,
} from './sanity';
import { VrboBestTimeToBookService } from './sanity/vrboBestTimeToBookTemplateService';
import { PageTheme, FeedType } from 'src/components/common/module-util';
import getPageTags from './helpers/getTags';
import { updateAppDownloadModulePosition } from './helpers/updateAppDownloadModulePosition';
import { isNotBtbUrl } from 'src/controllers/football/route-mapper';

export class SanityStore extends Store {
  @observable public campaignName: string = undefined;
  @observable public sanityPageData: any = undefined;
  @observable public isHideThreeUp: boolean = false;
  @observable public templateParams: object = {};

  private batch1TemplateService: Batch1TemplateService;
  private batch2TemplateService: Batch2TemplateService;
  private batch3TemplateService: Batch3TemplateService;
  private visitUsaTemplateService: VisitUsaTemplateService;
  private magazineTemplateService: MagazineTemplateService;
  private vrboBestTimeToBookService: VrboBestTimeToBookService;
  private allStudioTemplateService: AllStudioTemplateService;
  private expediaLiveTemplateService: ExpediaLiveTemplateService;
  private oceanHotelService: OceanHotelService;

  public constructor(
    private sanityApiSource: SanityApiSource,
    state: SerializedData = {},
    logger: Logger = NOOP_LOGGER,
  ) {
    super(state, logger);
    SanityApiSourceSingleton.setInstance(this.sanityApiSource);
    this.batch1TemplateService = new Batch1TemplateService();
    this.batch2TemplateService = new Batch2TemplateService();
    this.batch3TemplateService = new Batch3TemplateService();
    this.visitUsaTemplateService = new VisitUsaTemplateService();
    this.magazineTemplateService = new MagazineTemplateService();
    this.vrboBestTimeToBookService = new VrboBestTimeToBookService();
    this.allStudioTemplateService = new AllStudioTemplateService();
    this.expediaLiveTemplateService = new ExpediaLiveTemplateService();
    this.oceanHotelService = new OceanHotelService();
    makeObservable(this);
  }

  public hydrate(data: SerializedData): void {
    delete data.sanityApiSource;
    Object.assign(this, data);
  }

  // Define the setMcicd method
  public setMcicd(templateName: string, feedName: string): any {
    // Assuming mcicd is a function that returns some value
    let param1 = 'cplite';
    let param2 = templateName;
    if (checkValue(feedName)) {
      param1 = templateName;
      param2 = feedName;
    }

    return 'mcicid=cp.' + param1 + '.' + param2;
  }

  public setAdUnits(pageData): any {
    // Filter for ad units with placement 'Middle'
    const adUnitsMiddle = pageData?.content?.filter(
      (module) => module.module !== 'adUnit' || (module.module === 'adUnit' && module.layout !== 'Leaderboard'),
    );

    // Filter out ad units where placement is not 'Middle'
    const adUnitsNotMiddle = pageData?.content?.filter(
      (module) => module.module === 'adUnit' && module.layout === 'Leaderboard',
    );

    // Create the updated pageData object
    const updatedPageData = {
      ...pageData, // Keep other existing properties of pageData
      content: adUnitsMiddle, // Replace content with ad units that have 'Middle' placement
      adUnitsNotMiddle, // Add new property for ad units with placement other than 'Middle'
    };
    return updatedPageData; // Return the updated pageData
  }

  public async fetchCapsDataAPI(
    partner: string,
    templateName: string,
    feedName: string,
    pagelang: string,
    siteid: string,
    isCacheFeed: boolean,
    referrerId?: string,
    isDummyData?: boolean,
    batchQuery = 'b1',
    busaFilter?: string,
    brand?: string,
    isdraft?: string,
    callNumber?: number
  ): Promise<any> {
    if (!callNumber) return undefined; // Avoid processing if callNumber is undefined
  
    if (callNumber === 1) {
      if (this.sanityPageData !== undefined) return this.sanityPageData;
  
      this.campaignName = 'v3template';
      this.templateParams = { partner, feedName2: '' };
  
      const magazineArticleData = await this.sanityApiSource.fetchCapsAPIData(callNumber);
  
      if (!checkValue(magazineArticleData?.data?.allMagazineArticle)) return [];
  
      const allMagazineItem = magazineArticleData?.data?.allMagazineArticle?.[0];
      if (!checkValue(allMagazineItem)) return [];
  
      const campaign = allMagazineItem?.pos;
      const heroTags = getPageTags(allMagazineItem?.tags?.tag);
  
      const {
        group1,
        group2,
        group3,
        group4,
        heroChoice,
        heroSetting,
        sponsorHeroSettings,
        categoryLandingHeroSettings,
        hideMoreStories = false,
        ...rest
      } = allMagazineItem;
  
      let heroModule = null;
  
      if (heroChoice === 'Sponsored Hero' && checkValue(sponsorHeroSettings)) {
        heroModule = {
          module: sponsorHeroSettings._type,
          dataFeed: FeedType.Sanity,
          moduleType: 'hero',
          ...sponsorHeroSettings,
          tags: heroTags,
        };
      } else if (heroChoice === 'Category Landing Hero' && checkValue(categoryLandingHeroSettings)) {
        heroModule = {
          module: categoryLandingHeroSettings._type,
          dataFeed: FeedType.Sanity,
          moduleType: 'hero',
          ...categoryLandingHeroSettings,
          tags: heroTags,
        };
      } else if (checkValue(heroSetting)) {
        heroModule = {
          module: heroSetting._type,
          dataFeed: FeedType.Sanity,
          moduleType: 'hero',
          ...heroSetting,
          tags: heroTags,
        };
      }
  
      const formattedModules = [...(group1 ?? []), ...(group2 ?? []), ...(group3 ?? []), ...(group4 ?? [])].map(
        (module) => ({
          module: module?._type,
          ...module,
          dataFeed: FeedType.Sanity,
        })
      );
  
      const finalFormattedModules = updateAppDownloadModulePosition(
        formattedModules,
        allMagazineItem?.tags?.appModule,
        campaign
      );
  
      const pageData = {
        ...rest,
        ...campaign,
        content: heroModule ? [heroModule, ...finalFormattedModules] : finalFormattedModules,
        isArticlePage: !hideMoreStories && isNotBtbUrl(feedName),
        pageTheme: PageTheme.BrandLight,
        dataFeed: FeedType.Sanity,
        globalNavigationData: campaign || null,
        ignoreTextTruncate: true,
      };
  
      this.sanityPageData = {
        ...this.setAdUnits(pageData),
        tracking: {
          mcicid: this.setMcicd(templateName, feedName),
          partner,
          templateName,
          feedName,
          feedName2: '',
          pageLang: pagelang,
          batchQuery,
          siteid,
          isSanityHomePage: !checkValue(feedName),
          isDummyData,
        },
      };
  
      return this.sanityPageData;
    } else if (this.sanityPageData !== undefined) {


      const magazineArticleData = await this.sanityApiSource.fetchCapsAPIData(2);
  
      if (!checkValue(magazineArticleData?.data?.allMagazineArticle)) return this.sanityPageData;
  
      const allMagazineItem = magazineArticleData?.data?.allMagazineArticle?.[0];
      if (!checkValue(allMagazineItem)) return [];
  
      const { group1, group2, group3, group4 } = allMagazineItem;
  
      const formattedModules = [...(group1 ?? []), ...(group2 ?? []), ...(group3 ?? []), ...(group4 ?? [])].map(
        (module) => ({
          module: module?._type,
          ...module,
          dataFeed: FeedType.Sanity,
        })
      );
  
      this.sanityPageData = {
        ...this.sanityPageData,
        content: [...this.sanityPageData.content, ...formattedModules],
      };
  
      return this.sanityPageData;
    }
  
    return undefined;
  }
  

  @action
  public async fetchSanityPageData(
    partner: string,
    templateName: string,
    feedName: string,
    pagelang: string,
    siteid: string,
    isCacheFeed: boolean,
    referrerId?: string,
    isDummyData?: boolean,
    batchQuery = 'b1',
    busaFilter?: string,
    brand?: string,
    isdraft?: string,
  ): Promise<any> {
    let pageData = undefined;

    if (this.sanityPageData === undefined) {
      // Logic for fetching data from Sanity
      // cmslite and spotlight are the only valid values for the batchQuery parameter
      const dataFetchServiceMatcher = {
        b1: () => this.batch1TemplateService.fetchAllBatch1Template(partner, templateName, isDummyData),
        b2: () => this.batch2TemplateService.fetchAllBatch2Template(partner, templateName, isDummyData),
        b3: () => this.batch3TemplateService.fetchAllBatch3Template(partner, templateName, isDummyData),
        busa: () =>
          checkValue(feedName)
            ? this.visitUsaTemplateService.fetchVisitUsaSubTemplate(feedName, pagelang, isDummyData, busaFilter)
            : this.visitUsaTemplateService.fetchVisitUsaHomeTemplate(partner, templateName, pagelang, isDummyData),
        magazine: () =>
          checkValue(feedName)
            ? this.magazineTemplateService.fetchMagazineArticleTemplate(
                partner,
                templateName,
                feedName,
                isDummyData,
                pagelang,
                brand,
                isdraft,
              )
            : this.magazineTemplateService.fetchMagazineHomeTemplate(
                partner,
                templateName,
                isDummyData,
                pagelang,
                brand,
                isdraft,
              ),
        btb: () =>
          this.vrboBestTimeToBookService.fetchVrboBestTimeToBookServiceTemplate(
            partner,
            templateName,
            feedName,
            isDummyData,
            pagelang,
            brand,
          ),
        all: () =>
          this.allStudioTemplateService.fetchAllStudioServiceTemplate(partner, templateName, isDummyData, pagelang),
        expediaLive: () =>
          this.expediaLiveTemplateService.featchExpediaLiveHome(
            partner,
            templateName,
            isDummyData,
            pagelang,
            brand,
            isdraft,
          ),
        oceanhotel: () =>
          checkValue(feedName)
            ? this.oceanHotelService.fetchSubTemplate(
                partner,
                templateName,
                feedName,
                isDummyData,
                pagelang,
                brand,
                isdraft,
              )
            : this.oceanHotelService.fetchHomeTemplate(partner, templateName, isDummyData, pagelang, brand, isdraft),
      };

      pageData = dataFetchServiceMatcher[batchQuery] ? await dataFetchServiceMatcher[batchQuery]() : [];

      if (Array.isArray(pageData) && pageData.length === 0) {
        pageData = 'campaign not found';
      } else {
        const mcicd = this.setMcicd(templateName, feedName);
        const pageDataWithAdUnits = this.setAdUnits(pageData);

        // Consolidating tracking object for clarity
        const tracking = {
          mcicid: mcicd,
          partner: partner,
          templateName: templateName,
          feedName: feedName,
          feedName2: '',
          pageLang: pagelang,
          batchQuery: batchQuery,
          siteid: siteid,
          isSanityHomePage: !checkValue(feedName),
          isDummyData: isDummyData,
        };

        this.sanityPageData = {
          ...pageDataWithAdUnits,
          tracking: tracking,
        };
      }
      this.campaignName = 'v3template';
      this.templateParams = {
        partner: partner,
        templateName: templateName,
        feedName: feedName,
        feedName2: '',
      };
    } else {
      pageData = this.sanityPageData;
    }

    return pageData;
  }

  @action
  public async hideThreeUpCard(isHideThreeUp: boolean): Promise<any> {
    this.isHideThreeUp = isHideThreeUp;
  }
}
