import { codeSplit, Controller, FetchOptions, Result, FetchPageOptions } from 'bernie-core';
import { PageStore } from 'bernie-plugin-mobx';
import {
  PageData,
  Response,
  PropertyMeta,
  PaginationAttribute,
  AdditionalMeta,
  ContentLanguageMeta,
} from 'bernie-http';
import { NOOP_LOGGER } from 'bernie-logger';
import { CpCmsLiteStore } from 'src/stores';
import { CpCmsLiteSource } from 'src/sources/cp-cmslite-source';
import { SeoPropertyMeta } from 'src/common/utils/types';
import { getPageData, pageDataProps } from 'src/utils/controller-utils';
import { checkValue } from 'src/components/functions';
import { SanityStore } from 'src/stores/cp-sanity-store';
import { buildSanityApiSource } from 'src/stores/server';
import { routeMapper } from 'src/controllers/football/route-mapper';
import { useLocation } from 'react-router-dom-v5-compat';

export class FootballController implements Controller {
  public pageId = 'Sports';
  public path = '/:langOrLocale?/:partner?/:templateName?/:feedName?/:feedName2?';
  public routeName = 'sports';
  public bundles = [];
  public routeData = {
    rfrrNamespace: 'MESO3.SPORTS',
    pageName: 'page.sports',
  };
  public caseSensitive = true;
  /**
   * Components may be referred to directly
   *   `public component = FootballHubPage;`
   *
   * Or, the preferred option is to create a code split point that will generate a new bundle.
   *   Use webpack's magic comments `webpackChunkName: "INSERT_FRIENDLY_NAME_HERE"`
   *   This will give it a friendly name for the name of the file.
   */
  public component = codeSplit(() => import(/* webpackChunkName: "football" */ '../../views/football/home-page'));
  public exact = false;
  public accelerateTemplates = ['sports', 'see', 'acc'];
  public conciergeTemplates = ['prepare'];
  public mediaStudioTemplates = ['media-studio'];
  public subPartnerTemplates = ['spotlight', 'mirror'];
  public quizTemplates = ['spotlightquiz'];
  public validTemplatePartner = [...this.accelerateTemplates, ...this.conciergeTemplates, ...this.mediaStudioTemplates];
  public magazineTemplatesPattern = /^(magazine|tempmagazine)/i;
  public goTemplatesPattern = ['go'];

  public vacationRentals = [
    'vacation-rentals-of-the-year',
    'vacation-rentals-of-the-year-ca',
    'proprietes-de-vacances-de-l-annee',
    'holiday-homes-of-the-year',
    'holiday-homes-of-the-year-au',
    'holiday-homes-of-the-year-nz',
    'locations-de-vacances-de-l-annee',
    'ferienhaeuser-des-jahres',
  ];

  public pathParams(params: any) {
    const paramsLang = checkValue(params.langOrLocale) ? params.langOrLocale : '';
    const paramsPartner = checkValue(params.partner) ? params.partner : '';
    const paramsTemplateName = checkValue(params.templateName) ? params.templateName : '';
    const paramsFeedName = checkValue(params.feedName) ? params.feedName : '';
    const paramsFeedName2 = checkValue(params.feedName2) ? params.feedName2 : '';

    // Regex to validate lang like en-us, fr-fr , en, fr
    //const langPattern = /^[a-z]{2}(-[a-z]{2})?$/;
    const langPattern = /^(?!go$)[a-z]{2}(-[a-z]{2})?$/; // ignore 'go' because it is a partner name

    // Check if the first param is language
    if (!(checkValue(paramsLang) && langPattern.test(paramsLang))) {
      // If lang is present and valid, assign parameters directly
      params = {
        ...params,
        lang: '',
        partner: paramsLang,
        templateName: paramsPartner,
        feedName: paramsTemplateName,
        feedName2: paramsFeedName,
      };
    }

    let feedName = '';
    let partner = checkValue(params.partner) ? params.partner : '';
    let templateName = checkValue(params.templateName) ? params.templateName : '';

    // Accelerate Template
    if (this.accelerateTemplates.includes(partner.toLowerCase())) {
      // Home page route - no feed name
      // Sub page - feed name
      if (checkValue(params?.feedName)) {
        feedName = params.feedName;
      } else {
        feedName = '';
      }
    }
    // Concierge Template
    else if (this.conciergeTemplates.includes(partner.toLowerCase())) {
      feedName = '';
    }
    // Media Studio Template
    else if (this.mediaStudioTemplates.includes(partner.toLowerCase())) {
      // If the media stdio blog
      // set the feed name to feedName2
      // blog --> /capsapi/template-cplite/mstudiodev/adventures-in-abu-dhabi/en_GB/3
      feedName = params?.feedName?.toLocaleLowerCase() === 'blog' ? params?.feedName2 : '';
    }
    // Spotlight Template
    else if (this.subPartnerTemplates.includes(partner.toLowerCase())) {
      feedName = templateName;
      templateName = partner;
    }
    // Quiz Templates
    else if (this.quizTemplates.includes(partner.toLowerCase())) {
      feedName = templateName;
      templateName = 'spotlight';
    }
    // Magazine Template
    // magazine --> /magazine"  | /tempmagazine
    // magazine --> /magazine/abc" | /tempmagazine/abc"
    else if (this.magazineTemplatesPattern.test(partner.toLowerCase())) {
      feedName = checkValue(templateName) ? templateName : '';
      templateName = partner?.replace('temp', '');
      //partner = 'see'; // commented out because discuss with gayan
    } else if (this.goTemplatesPattern.includes(partner.toLowerCase())) {
      feedName = checkValue(templateName) ? templateName : '';
      templateName = partner;
    }
    // Vacation Rentals Template
    else if (this.vacationRentals.includes(partner.toLowerCase())) {
      feedName = partner;
      templateName = 'magazine';
      partner = 'see';
    }
    // Default
    else {
      if (checkValue(params?.feedName)) {
        feedName = params.feedName;
      } else {
        feedName = '';
      }
    }

    return { partner, templateName, feedName };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async fetch(options: FetchOptions): Promise<Result> {
    const {
      request: { params, query },
      request: { context },
      response,
    } = options;

    // Get CpCmsLiteStore and SanityStore
    const cpcmslitefeed = options.stores.get<CpCmsLiteStore>('cpcmslitefeed');
    const sanityStore = options.stores.get<SanityStore>('sanityStore');

    const { partner, templateName, feedName } = this.pathParams(params);

    // Only for test route
    if (templateName === 'test') {
      await cpcmslitefeed.fetchCpCmsLiteTestPageData();
      return Promise.resolve({});
    }
    if (templateName === 'cpds-demo') {
      await cpcmslitefeed.fetchCpdsTestPageData();
      return Promise.resolve({});
    }

    const optionsRequest = options.request;
    const contextSite = optionsRequest?.context?.site;
    // const domain = contextSite?.domain;
    const host = optionsRequest?.context?.['host'];

    const isHotelsCom = host?.indexOf('hotels') > -1;
    const locale =
      checkValue(query.lang) && query.lang !== '' ? query.lang : isHotelsCom ? this.hotelsLocal() : context?.locale;
    const siteId = contextSite?.id;
    const brand = contextSite?.brand || 'expedia';

    const isDraft = checkValue(query.isdraft) && query.isdraft === '1' ? '1' : '0';

    let isCacheFeed = checkValue(query.iscache) ? !(query.iscache === '0') : true;
    isCacheFeed = checkValue(query.ispreview) ? !(query.ispreview === '1') : isCacheFeed;
    //const isCacheNew = isCacheFeed;

    // Referrer ID check for Facebook and Email to be appended to mcicids
    const referrerId =
      checkValue(query.emlcid) || checkValue(query.EMLCID)
        ? 'eml'
        : checkValue(query.fbclid) || checkValue(query.FBCLID)
        ? 'fb'
        : '';

    let data = undefined;

    // This logic need to change just testing logic
    //const isSanityData = checkValue(query.sanity) ? (query.sanity === '1') : false;
    const routeMapperData = routeMapper(partner, templateName, feedName, query);
    const isSanityData = routeMapperData?.isSanityData;

    const isTest = checkValue(query.istest) ? (query.istest === '1') : false;
    if (partner === "see" && isTest) {
      data = await sanityStore.fetchCapsDataAPI(
        partner,
        templateName,
        feedName,
        locale,
        siteId?.toString(),
        isCacheFeed,
        referrerId,
        undefined,
        undefined,
        undefined,
        brand,
        isDraft,
        1
      );
    }
    else if (isSanityData) {
      const { isSanityDummyData, batchQuery, busaFilter } = routeMapperData;
      data = await sanityStore.fetchSanityPageData(
        partner,
        templateName,
        feedName,
        locale,
        siteId?.toString(),
        isCacheFeed,
        referrerId,
        isSanityDummyData,
        batchQuery,
        busaFilter,
        brand,
        isDraft,
      );
    } else {
      data = await cpcmslitefeed.fetchFeedPageData(
        partner,
        templateName,
        feedName,
        locale,
        siteId?.toString(),
        isCacheFeed,
        referrerId,
      );
    }
    const isSanity = data.dataFeed === 'sanity';
    // const isLive = typeof data?.tags?.isLive === "boolean" ? data.tags.isLive : false;
    const isLive = true;
    if (
      checkValue(data) &&
      !checkValue(data?.error) &&
      !(typeof data === 'string' && data === 'campaign not found') &&
      (!isSanity || isLive || (query?.ispreview === '1' && !isLive))
    ) {
      const page: PageStore = options.stores.get<PageStore>('page');
      // Concierge return data as data.data and others return as data
      data = checkValue(data.data) ? data.data : data;
      const { isEnabledGoogleAnalytics } = routeMapperData;

      if (isEnabledGoogleAnalytics) {
        // Global site tag (gtag.js) - Google Analytics
        page.scripts?.push(
          {
            async: true,
            src: 'https://www.googletagmanager.com/gtag/js?id=G-TDT0RQG31J',
          },
          {
            async: true,
            src: 'https://a.travel-assets.com/travel-assets-manager/cmslite-scripts/cmslite-ga4-tagmgr.js',
          },
        );
      }

      if (!isSanity) {
        // Add Dynamic Style
        if (checkValue(data.bodyFont)) {
          const fontFamily = data.bodyFont.toLowerCase().split(' ').join('-');
          if (fontFamily === 'mena-grotesk')
            page?.stylesheets?.push(`https://mesocp.azureedge.net/media/3cchvsbu/mena-grotesk.css`);
          if (fontFamily === 'rf-dewi-extended')
            page?.stylesheets?.push('https://mesocp.azureedge.net/media/oshn5rdk/rfdewiextended.css');
        }
      }

      if (checkValue(data.templateType) && (data.templateType === 'spotlight' || data.templateType === 'cplite')) {
        page.scripts?.push({
          async: true,
          src: 'https://a.travel-assets.com/travel-assets-manager/templated-microsite/ms-heatmap.js',
        });

        if (checkValue(data.showMarkerIo) && data.showMarkerIo === 'True') {
          page.scripts?.push({
            async: true,
            src: 'https://a.travel-assets.com/travel-assets-manager/marker-io/marker-io-new.js',
          });
        }
      }

      if (isSanityData && checkValue(data?.showMarkerIo) && data.showMarkerIo.toString().toLowerCase() === 'true') {
        page.scripts?.push({
          async: true,
          src: 'https://a.travel-assets.com/travel-assets-manager/marker-io/marker-io-new.js',
        });
      }
    } else {
      const pageRfrr = checkValue(templateName) ? 'cplite' : templateName;
      FootballController.redirectResponse(response, `/404/?rfrr=${pageRfrr}`);
      return Promise.resolve({});
    }

    return Promise.resolve({});
  }

  public async fetchPageData(options: FetchPageOptions): Promise<PageData> {
    const { params, query, context, request } = options;
    const { partner, templateName, feedName } = this.pathParams(params);

    const cpcmslitefeed = new CpCmsLiteStore(new CpCmsLiteSource(), {}, NOOP_LOGGER);
    const sanityStore = new SanityStore(buildSanityApiSource(request), {}, NOOP_LOGGER);

    // Only for test route
    if (templateName === 'test') {
      await cpcmslitefeed.fetchCpCmsLiteTestPageData();
      return Promise.resolve({ title: 'Test Page' }) as Promise<PageData>;
    }
    // Cpds demo page route
    if (templateName === 'cpds-demo') {
      await cpcmslitefeed.fetchCpdsTestPageData();
      return Promise.resolve({ title: 'CPDS Demo' }) as Promise<PageData>;
    }

    const contextSite = context?.site;
    const domain = contextSite?.domain || request?.headers?.host;
    const referrerId =
      checkValue(query.emlcid) || checkValue(query.EMLCID)
        ? 'eml'
        : checkValue(query.fbclid) || checkValue(query.FBCLID)
        ? 'fb'
        : '';
    const isHotelsCom = domain?.indexOf('hotels') > -1;
    const locale =
      checkValue(query.lang) && query.lang !== '' ? query.lang : isHotelsCom ? this.hotelsLocal() : context?.locale;

    const siteId = contextSite?.id || 1;
    const brand = contextSite?.brand || 'expedia';
    let isCacheFeed = checkValue(query.iscache) ? !(query.iscache === '0') : true;
    isCacheFeed = checkValue(query.ispreview) ? !(query.ispreview === '1') : isCacheFeed;
    //const isSanityData = checkValue(query.sanity) ? (query.sanity === '1') : false;

    const routeMapperData = routeMapper(partner, templateName, feedName, query);
    const isSanityData = routeMapperData?.isSanityData;

    const isDraft = checkValue(query.isdraft) && query.isdraft === '1' ? '1' : '0';
    let data = undefined;

    const isTest = checkValue(query.istest) ? (query.istest === '1') : false;
    if (partner === "see" && isTest) {
      data = await sanityStore.fetchCapsDataAPI( 
        partner,
        templateName,
        feedName,
        locale,
        siteId?.toString(),
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        brand,
        isDraft,
        1);
    }
    else if (isSanityData) {
      const { isSanityDummyData, batchQuery, busaFilter } = routeMapperData;
      data = await sanityStore.fetchSanityPageData(
        partner,
        templateName,
        feedName,
        locale,
        siteId?.toString(),
        isCacheFeed,
        referrerId,
        isSanityDummyData,
        batchQuery,
        busaFilter,
        brand,
        isDraft,
      );
    } else {
      data = await cpcmslitefeed
        .fetchFeedPageData(partner, templateName, feedName, locale, siteId.toString(), isCacheFeed, referrerId)
        .then((data) => {
          return data;
        });
    }

    let seoData = {
      title: 'This is title',
      description: 'This is description',
      url: '',
      locale: 'en_US',
      siteName: '',
      image: '',
      isLive: null,
    };

    const isSanity = data.dataFeed === 'sanity';
    // const isLive = typeof data?.tags?.isLive === "boolean" ? data.tags.isLive : false;
    const isLive = true;

    if (
      checkValue(data) &&
      !checkValue(data?.error) &&
      !(typeof data === 'string' && data === 'campaign not found') &&
      (!isSanity || isLive || (query?.ispreview === '1' && !isLive))
    ) {
      // Concierge return data as data.data and others return as data
      data = checkValue(data.data) ? data.data : data;

      const { title, description, image, canonical, isLive } = this.getSeoData(data, data?.dataFeed);

      let canonicalPath = '';
      if (canonical) canonicalPath = canonical;
      else if (data?.canonical) canonicalPath = data?.canonical;
      else canonicalPath = `https://${domain}${request?.url}`;

      //const pageName = `page.${partner}${templateName !== '' ? '.' + templateName : ''}${feedName !== '' ? '.' + feedName : ''}`;

      let siteName = domain.replace('www.', '');
      siteName = siteName.charAt(0).toUpperCase() + siteName.slice(1);

      seoData = {
        title: title === '' ? 'This is title' : title,
        description: description === '' ? 'This is description' : description,
        url: this.removeURLParams(canonicalPath),
        locale: locale,
        siteName: siteName,
        image: image,
        isLive,
      };
    }

    const pageDataProps: pageDataProps = {
      partner: params?.partner,
      templateName: params?.templateName,
      feedName: params?.feedName,
    };

    const seoPropertyMeta: SeoPropertyMeta = {
      title: seoData?.title === '' ? 'This is title' : seoData?.title,
      description: seoData?.description === '' ? 'This is description' : seoData?.description,
      url: this.removeURLParams(seoData?.url),
      locale: seoData?.locale,
      siteName: seoData?.siteName,
      image: seoData?.image.split('?')[0],
    };

    const additionalMeta: AdditionalMeta[] = [
      this.buildContentLanguageMeta(seoData?.locale),
      ...this.addResourceHints(isSanityData),
      ...this.buildPropertyMeta(seoPropertyMeta),
    ];

    let seo = {};
    if (isDraft === '1') {
      seo = {
        title: seoPropertyMeta.title,
        seo: {
          description: seoData?.description,
          canonical: seoData?.url,
          ...{ additionalMeta },
          robots: 'noindex, nofollow',
        },
      };
    } else {
      seo = {
        title: seoPropertyMeta.title,
        seo: {
          description: seoData?.description,
          canonical: seoData?.url,
          ...{ additionalMeta },
        },
      };
    }

    const pageData = getPageData(pageDataProps);
    return Promise.resolve({
      ...seo,
      ...pageData,
    }) as Promise<PageData>;
  }

  /**
   * @param URL
   * @returns URL without parameters
   * remove all parameters except langid
   */
  // private removeURLParams(url: string) {
  //   const urls = url.split('?');
  //   if (urls.length === 1 || (urls.length > 1 && !urls[1].includes('langid'))) {
  //     return urls[0];
  //   }
  //   const params = urls.length > 1 && urls[1].split("&");
  //   const langid = params.filter(param => param.includes('langid'));
  //   return urls[0] + '?' + langid;
  // }

  public removeURLParams(url: string): string {
    if (url.indexOf('?') > -1) {
      const [baseUrl, query] = url.split('?');
      const params = query.split('&');
      const filteredParams = params.find((param) => param.startsWith('langid='));
      if (filteredParams) {
        return baseUrl + '?' + filteredParams;
      } else {
        return baseUrl;
      }
    } else {
      return url;
    }
  }

  // tslint:disable-next-line:prefer-function-over-method
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public hotelsLocal() {
    const siteLang = 'en_us';
    return checkValue(siteLang) ? 'en_us' : siteLang;
  }

  public buildContentLanguageMeta = (locale) => {
    return new ContentLanguageMeta('Content-language', locale);
  };

  public buildPropertyMeta(seoPropertyMeta: SeoPropertyMeta) {
    const title = new PropertyMeta('og:title', seoPropertyMeta?.title);
    const type = new PropertyMeta('og:type', 'website');
    const description = new PropertyMeta('og:description', seoPropertyMeta?.description);
    const locale = new PropertyMeta('og:locale', seoPropertyMeta?.locale);
    const siteName = new PropertyMeta('og:site_name', seoPropertyMeta?.siteName);
    const url = new PropertyMeta('og:url', seoPropertyMeta?.url);
    const image = new PropertyMeta('og:image', seoPropertyMeta?.image);

    return [title, type, description, locale, siteName, url, image];
  }

  public getSeoData(data: any, campaignType: string) {
    let title = '';
    let description = '';
    let image = '';
    let canonical = '';
    let isLive = null;

    // Seo data fetch from umbraco related feeds
    if (checkValue(campaignType) && campaignType === 'sanity') {
      title = checkValue(data?.tags?.seoTitle) ? data?.tags?.seoTitle : '';
      description = checkValue(data?.tags?.seoDesc) ? data?.tags?.seoDesc : '';
      let seoHero = '';
      if (checkValue(data?.content, true)) {
        const heroModule = data?.content?.find((c) => c?.moduleType && c?.moduleType === 'hero');
        const heroVideoCover = heroModule?.cmsMedia?.videoCover?.asset?.url;
        const busaHeroImage = heroModule?.image?.asset?.url;
        const heroImage = heroModule?.cmsMedia?.image?.asset?.url;
        seoHero = checkValue(busaHeroImage)
          ? busaHeroImage
          : checkValue(heroImage)
          ? heroImage
          : checkValue(heroVideoCover)
          ? heroVideoCover
          : '';
      }

      image = seoHero;
      canonical = checkValue(data?.tags?.seoCanonical) ? data?.tags?.seoCanonical : '';
      isLive = data?.tags?.isLive;
    } else {
      // Seo data fetch from sanity related feeds
      title = checkValue(data.sEOTitle) ? data.sEOTitle : checkValue(data.seoTitle) ? data.seoTitle : '';
      description = !checkValue(data.sEODescription) ? '' : data.sEODescription;
      image = checkValue(data?.oGImage?.[0]?.url) ? data?.oGImage?.[0]?.url : this.seoHeroImage(data);
    }
    return { title, description, image, canonical, isLive };
  }

  // tslint:disable-next-line:prefer-function-over-method
  public seoHeroImage(data: any) {
    let image = '';
    const templateType = data?.templateType;
    if (templateType === 'cplite') {
      const partnerGlobalHero = data?.content?.find((c) => c?.module?.indexOf('globalHero') > -1);
      const partnerGlobalHerov2 = data?.content?.find((c) => c?.module?.indexOf('v2GlobalHero') > -1);
      const partnerCoopHero = data?.content?.find((c) => c?.module?.indexOf('heroModuleCoop') > -1);
      const v3HeroModule = data?.content?.find((c) => c?.module?.indexOf('v3HeroModule') > -1);
      const imageGlobal = partnerGlobalHero?.heroData?.heroImage?.[0]?.url;
      const imageGlobalV2 = partnerGlobalHerov2?.heroData?.heroImage?.[0]?.url;
      const imageCoop = partnerCoopHero?.heroImage?.[0]?.url;
      const imageV3HeroModule = v3HeroModule?.heroImage?.[0]?.url;
      image = checkValue(imageGlobal)
        ? imageGlobal
        : checkValue(imageGlobalV2)
        ? imageGlobalV2
        : checkValue(imageCoop)
        ? imageCoop
        : checkValue(imageV3HeroModule)
        ? imageV3HeroModule
        : '';
    } else {
      switch (templateType) {
        case 'mirror': {
          image = checkValue(data.heroImage?.[0]) ? data.heroImage?.[0]?.url : '';
          break;
        }
        case 'spotlight': {
          const partnerCoopHeroModule = data?.pageContent?.find((c) => c?.module?.indexOf('heroModuleCoop') > -1);
          if (!checkValue(partnerCoopHeroModule?.heroImage?.[0])) {
            const partnerSpotlightHero = data?.pageContent?.find((c) => c?.module?.indexOf('globalHero') > -1);
            const partnerGlobalHerov2 = data?.pageContent?.find((c) => c?.module?.indexOf('v2GlobalHero') > -1);
            const partnerOldSpotlightHero = data?.pageContent?.find(
              (c) => c?.module?.indexOf('heroModuleSpotlight') > -1,
            );
            const imageNew = partnerSpotlightHero?.heroData?.heroImage?.[0]?.url;
            const imageOld = partnerOldSpotlightHero?.heroImageNew?.[0]?.url;
            const imageV2 =
              partnerGlobalHerov2?.heroData?.heroImage?.[0]?.url ||
              partnerGlobalHerov2?.heroData?.backgroundPhotoStaticVideoImage?.[0]?.url;
            const quizImage = data?.heroImageMulti?.[0]?.url;
            image = checkValue(imageNew)
              ? imageNew
              : checkValue(imageOld)
              ? imageOld
              : checkValue(imageV2)
              ? imageV2
              : checkValue(quizImage)
              ? quizImage
              : '';
          } else {
            image = partnerCoopHeroModule?.heroImage?.[0]?.url;
          }
          break;
        }
        case 'cplite': {
          image = checkValue(data.backgroundPhotoImage?.[0]) ? data.backgroundPhotoImage?.[0]?.url : '';
          break;
        }
        case 'concierge': {
          image = checkValue(data?.backgroundPhoto?.[0]) ? data?.backgroundPhoto?.[0]?.url : '';
          break;
        }
        default: {
          image = '';
          break;
        }
      }
    }
    return image;
  }

  public addResourceHints(isSanityData): AdditionalMeta[] {
    return [...this.buildPreconnectLinks(isSanityData)];
  }

  // tslint:disable-next-line:prefer-function-over-method
  public buildPreconnectLinks(isSanityData = false) {
    if (isSanityData) {
      return [
        new PaginationAttribute('preconnect', 'https://cdn.sanity.io/'),
        new PaginationAttribute('dns-prefetch', 'https://cdn.sanity.io/'),
        new PaginationAttribute('preconnect', 'https://fonts.googleapis.com/'),
        new PaginationAttribute('dns-prefetch', 'https://fonts.googleapis.com/'),
      ];
    } else {
      return [
        new PaginationAttribute('preconnect', 'https://a.travel-assets.com'),
        new PaginationAttribute('dns-prefetch', 'https://a.travel-assets.com'),
        new PaginationAttribute('preconnect', 'https://b.travel-assets.com'),
        new PaginationAttribute('dns-prefetch', 'https://b.travel-assets.com'),
        new PaginationAttribute('preconnect', 'https://c.travel-assets.com'),
        new PaginationAttribute('dns-prefetch', 'https://c.travel-assets.com'),
        new PaginationAttribute('preconnect', 'https://mesocp.azureedge.net/'),
        new PaginationAttribute('dns-prefetch', 'https://mesocp.azureedge.net/'),
        new PaginationAttribute('preconnect', 'https://mesocp2-2.azureedge.net/'),
        new PaginationAttribute('dns-prefetch', 'https://mesocp2-2.azureedge.net/'),
        new PaginationAttribute('preconnect', 'https://mesocp3.azureedge.net/'),
        new PaginationAttribute('dns-prefetch', 'https://mesocp3.azureedge.net/'),
        new PaginationAttribute('preconnect', 'https://fonts.googleapis.com/'),
        new PaginationAttribute('dns-prefetch', 'https://fonts.googleapis.com/'),
        new PaginationAttribute('preconnect', 'https://images.trvl-media.com/'),
        new PaginationAttribute('dns-prefetch', 'https://images.trvl-media.com/'),
      ];
    }
  }

  private static redirectResponse(response: Response, redirectUrl: string) {
    response.redirect(301, redirectUrl);
  }
}
