import { UitkFigureAspectRatioType } from 'uitk-react-images';
import { PlayerOptions } from 'video.js';
import { PrimaryButtonProps } from 'src/components/common/modules/button/button';
import { AnimationWrapperProps } from 'src/components/common/modules/animation/animation';
import { CopyRightProps } from 'src/components/common/modules/copyrightimg/copyrightimg';

export interface HeroProps {
  heroImageNew: any;
  heroImageMobile?:any;
  heroImageCopyright: string;
  heroImageCopyrightCaption: string;
  heroImageDescription?: string;
  heroTitleDesktop: string;
  heroTitleMobile: string;
  device: string;
  pageId: string;
  heroSubTextDesktop?: string;
  heroSubTextMobile?: string;
  heroVideoType?: string;
  heroVideoId?: string;
  heroVideo?: string;
  heroVideoImageNew?: any;
  heroStyle?: string;
  heroOverlayImageDesktop?: string;
  heroOverlayImageMobile?: string;
  opacitySelect: number;
  siteid: any;
  campaignUrl: string;
  template: string;
  turnOffGradient: string;
  heroVideoPosition: string;
  breadcrumb: any;
  turnOffHeader?: any;
  barColorLeft?:any;
  barColorRight?:any;
  heroLogo?: any;
  heroLogoStyle?: string;
  breadcrumbHomeCopy?: string;
  breadcrumbParentCopy?: string;
  breadcrumbCurrentCopy?: string;
  turnOffCropsForHeroImage?: boolean;
  headerLogoSize?: string;
  turnOffSubheader?: string;
  backgroundColor?: string;
  turnOffBreadcrumb?: string;
  textColor?: string;
  applyOriginalRatioForBackgroundPhoto?: string;
  heroSlimMode?: string;
  textSize?: string;
  heroScrollDownArrow?:string;
  heroVideoHeight?: string;
  heroVideoWidth?: string;
  heroTitleWidth?: string;
  heroSubTextWidth?: string;
}

export interface CopyrightCameraProps {
  copyrightText?: any;
  copyrightCation?: any;
  position?: 'tl' | 'tr' | 'br' | 'bl';
  iconSize?: 'sm' | 'md' | 'lg';
}

export interface VideoPlayerProps {
  videoOptions?: PlayerOptions;
  isBackground?: boolean;
  isMediaStudio?:boolean;
  isPipEnable?:boolean;
  isHero?:boolean;
  wrapperClass?: string;
  isHeroPositionStore?: boolean;
  width?: any;
  height?: any;
  staticImageOpacitySelect?: any;
}
export interface ModuleProps {
  defaultSetting?: any;
  data?: any;
  isWebflow?: boolean;
  isSpotlightPage?: boolean;
  articleStyle?: string;
  exClass?: string;
  device?: string;
  id?: string
}

export interface HotelWidgetProps {
  defaultSetting?: any;
  data?: any;
  hotelDeals?: any;
  isWebflow?: boolean;
  id?: string
}

export interface FlightWidgetProps {
  defaultSetting?: any;
  data?: any;
  flightDeals?: any;
  isWebflow?: boolean;
  id?: string;
}

export interface ModulePropsUITK {
  defaultSetting?: any;
  data?: any;
  moduleSettings?: any;
}

export interface BreadCrumb {
  DisplayName: string;
  Value: string;
}

export interface BreadCrumbProps {
  BreadCrumbs: BreadCrumb[];
  homeLoc: string;
  expTracking?:{
        templateType: string;
        campaignUrl: string;
        mainUrl: string;
        referrerId: string;
      };
  eventType?: 'onClick' | 'historyPush';
  isAnimate?: boolean;
  templateType?: string;
  homeHref?: string;
  middleLayerCopy?:string;
  middleLayerUrl?:string;
  isMiddleLayerEnable?:string;
}

export interface FigureProps {
  src: string;
  exClass?: string;
  imgClass?: string;
  ratio?: UitkFigureAspectRatioType;
  imageFit?: 'cover' | 'contain';
  alt?: string;
  children?: React.ReactNode;
  blankDefault?: boolean;
  placeholderImage?: boolean;
  isGradientActive?: boolean;
  copyRightProps?: CopyrightCameraProps;
  roundcorner?: any;
  imageProps?: any;
}
export interface ActivityWidgetProps {
  defaultSetting?: any;
  data?: any;  
}
export interface DoubleImageProps {
  imgName?: string;
  imgUrls: string;
  imgCaption?: string;
  imgCopyRight?: string;
  imgDesc?: string;
}

export interface DoubleImagesProps {
  doubleImages: DoubleImageProps[];
  isSpotlightPage?: boolean;
  data?: any,
  articleStyle?: string;
  id?: string;
}

export interface ContrastImagesProps {
  isSpotlightPage?: boolean;
  data?: any,
  articleStyle?: string;
  id?: string;
}


export interface SlickCarouselProps {
  cardArray: any;
  exClass?: string;
  customArrows?: boolean;
  popupCloseButton?: {
    onClickEvent?: (event: React.MouseEvent<HTMLElement>) => void;
  };
  settings: {
    dots: boolean;
    centerMode?: boolean;
    infinite: boolean;
    speed: number;
    slidesToShow: number;
    slidesToScroll: number;
    responsive: any;
    centerPadding?: string;
    prevArrow?: any;
    nextArrow?: any;
    beforeChange?: any;
    initialSlide?: any;
    swipe?: boolean;
    draggable?: boolean;
  };
}

export interface ImageCardProps {
  cardType?: 'fullImageCard' | 'sideImageCard' | 'animationImageCard';
  image?: string;
  imageAltText?: string;
  backgroundColor?: string;
  cardLogo?: any;
  cardCategory: string;
  cardTitle: string;
  subText?: string;
  onClickEvent?: (event: React.MouseEvent<HTMLElement>) => void;
  alt?: string;
  exClass?: string;
  index?: number;
  buttonProps?: PrimaryButtonProps;
  animationWrapperProps?: AnimationWrapperProps;
  overlayPanelColorColor?: string;
  overlayPanelTextColor?: string;
  isPopup?: boolean | false;
  cardLocation?: string;
  videoProps?: any;
  copyRightProps?: CopyRightProps;
  imageRatio?: string;
  imageGradient?: string;
  textColorType?: string;
  tileInstructionMobile?: string;
  videoBackgroundImageLogo?: any;
  gradient?: boolean;
  logoPosition?: string;
  localization?: any;
}

export interface GalleryCarouselWithAOSProps {
  galleryHeadline: string,
  gallerySubheadline: string,
  galleryMediaItems: any,
  carouselProps: SlickCarouselProps
}

export interface GalleryCarouselWithLazyloadingProps extends GalleryCarouselWithAOSProps {
  device: string
}
export interface QuizPanelModuleProps {
  defaultSetting: any;
  data: any;
  mccidData: any;
  isSpotlightPage: boolean;
  device: any;
  id?: string
}

export interface QuizHeaderProps {
  primaryColour: string;
  partnerLogo: string;
  partnerUrl: string;
  brandLogoWhite: string;
  brandLogo: string;
  isHotelCom: boolean;
  bookNowCTACopy: string;
  bookNowCTALink: string;
  partnerHistoryBack: any;
}

export interface QuizPanelProgressBarProps {
  primaryColour: string;
  completeLevel: any;
  partnerLogo: string;
  partnerUrl: string;
  siteID: any;
  campaignUrl: string;
  template: string;
  bookNowCTACopy: string;
  bookNowCTALink: string;
  secondaryColour: string;
}

export interface QuizPanelHomeContentProps {
  increaseLevel: () => any;
  homeContent: any;
  primaryColourName: string;
  btnColours: any;
}

export interface LeftPanelProps {
  quizImg: string;
  brandLogoWhite: string;
  heroImageCopyright: string;
  heroImageTurnOffGradient: string;
  isHotelCom: boolean;
}

export interface QuizImageProps {
  questionsData: any;
  getNextQuestions: (qAnswer: string, answerTheme: string, isCorrect: boolean) => any;
  device: string;
  questionsID: any;
  template: string;
  siteID: any;
  campaignUrl: string;
  handleHeroImage?: (url: string) => any;
  handleHeroImageCopyright?: (text: string, url: string) => any;
  handleHeroImageTurnOffGradient?: (gradient) => any;
}

export interface QuizTextProps {
  questionsData: any;
  getNextQuestions: (qAnswer: string, answerTheme: string, isCorrect: boolean) => any;
  device: string;
  questionsID: any;
  template: string;
  siteID: any;
  campaignUrl: string;
  isQuizEnd: boolean;
  isQuizStarted: boolean;
  handleHeroImage?: (url: string) => any;
  handleHeroImageCopyright?: (text: string, url: string) => any;
  handleHeroImageTurnOffGradient?: (gradient) => any;
}

export interface QuizRegisterFormProps {
  totalQuestions: number;
  userAnswers: any;
  totalCrroect: number;
  results: any;
  quiz_id: string;
  user_id: string;
  secondaryColour: string;
  primaryColour: string;
  fromHeading: string;
  submitCTACopy: string;
  successfulEntryCopy: string;
  subscribeToEmail: string;
  successfulEntryTitle: string;
  successfulCTACopy: string;
  successCTAStyle: string;
  successfulCTALink: string;
  termsCheckboxCopy: string;
  termsCopy: string;
  campaignUrl: string;
  template: string;
  siteID: any;
  updateSubmitted: (isSubmitted: boolean) => any;
  device: string;
  pageLang: string;
  isSubmitted: boolean;
  btnColours: any;
  fullNameCopy?: string;
  emailAddressCopy?: string;
  emailAddressValidationMsgCopy?: string;
  fullNameValidationMsgCopy?: string;
  reCAPTCHAValidationMsgCopy?: string;
  heroImageCopyright?: string;
  handleHeroImage?: (heroImageCurrent: string) => void;
  handleHeroImageCopyright?: (heroImageCopyRightsCurrent: string, heroImageCurrent: string) => void;
  handleHeroImageTurnOffGradient?: (heroImageTurnOffGradientCurrent: string) => void;
  updateCopyRight?: any;
}

export interface LozengeMessageV2Props {
  lozengeDetailMessage?: string;
  backgroundColor?: string;
  color?: string;
  defaultTextColor?: string;
  exClass?: string;
  iconStyle?: string;
  iconColor?: string;
} 

export interface StickerProps {
  stickerImage: any;
  stickerPosition: string;
  exClass: string;
} 

export enum ThemeColorMode {
  Light = 'light',
  Dark = 'dark'
}

export enum ThemeType {
  Brand = 'brand',
  Meso = 'meso'
}

export enum PageTheme {
  BrandLight = 'Brand-Light',
  BrandDark = 'Brand-Dark',
  MesoLight = 'Meso-Light',
  MesoDark = 'Meso-Dark'
}

export enum FeedType {
  Sanity = 'sanity',
  Umbraco = 'umbraco',
}