export const V3EditorialOneUpCarouselModuleData = {
  module: 'v3editorialOneUpCarousel',
  _type: 'EditorialOneUpCarousel',
  moduleColor: 'Primary',
  cards: Array.from({ length: 10 }, (_, index) => ({
    _type: 'basic1Up',
    _key: 'd343dcbe5e12a605fe5c29d36bc80056',
    title: 'An explorer’s guide to <em>Mexico City</em>',
    moduleColor: 'Primary',
    style: 'Left',
    author: null,
    publishDate: 'August 2023',
    subhead: 'Sponsored by [Brand]',
    image: {
      _key: null,
      _type: 'titledMedia',
      mediaType: 'Image',
      image: {
        _type: 'image',
        asset: {
          _type: 'sanity.imageAsset',
          _id: 'image-b9174b567b116532ebfd519323405ede97da85cf-644x644-png',
          assetId: 'b9174b567b116532ebfd519323405ede97da85cf',
          path: 'images/nxpteyfv/media-studio/b9174b567b116532ebfd519323405ede97da85cf-644x644.png',
          url: 'https://cdn.sanity.io/images/nxpteyfv/media-studio/b9174b567b116532ebfd519323405ede97da85cf-644x644.png',
        },
        crop: null,
        hotspot: null,
      },
      altText: null,
      videoUrl: null,
      aspectRatio: '1:1',
      theme: [
        {
          _id: '09378c441eeb3e2f04fcd9410edc82eb',
          _type: 'skosConcept',
          _createdAt: '2024-07-05T10:34:32Z',
          _updatedAt: '2024-08-07T05:04:14Z',
          _rev: 'R54bKVhXsQ7KqQM4Vf03ip',
          _key: null,
          prefLabel: 'City',
          definition: null,
          example: null,
          scopeNote: null,
          altLabel: null,
          hiddenLabel: null,
          baseIri: 'https://www.tbc.com',
          conceptId: '4460bc',
          historyNote: null,
          editorialNote: null,
          changeNote: null,
        },
        {
          _id: 'c0941877767f293ab15701000c2d867e',
          _type: 'skosConcept',
          _createdAt: '2024-07-05T10:34:48Z',
          _updatedAt: '2024-07-05T10:34:58Z',
          _rev: 'oAJvTCg2JagSJ7DoIQY2mr',
          _key: null,
          prefLabel: 'Nature',
          definition: null,
          example: null,
          scopeNote: null,
          altLabel: null,
          hiddenLabel: null,
          baseIri: 'https://www.tbc.com',
          conceptId: 'a3131a',
          historyNote: null,
          editorialNote: null,
          changeNote: null,
        },
      ],
      imageGradient: false,
      title: null,
    },
    imageGradient: 'No Gradient',
    logo: null,
    cta: {
      _key: null,
      ctaText: 'Read more',
      ctaUrl: 'https://expedia.com/',
      openUrlInNewTab: true,
      icon: null,
    },
    cta2: null,
    bodyTextRaw: [
      {
        children: [
          {
            _type: 'span',
            marks: [],
            text: 'Lorem ipsum dolor sit amet consectetur. Vel fermentum phasellus semper eu nulla venenatis viverra variusisque.',
            _key: 'bd9c81dcc185',
          },
        ],
        _type: 'block',
        style: 'normal',
        _key: '0ec731f59809',
        markDefs: [],
      },
    ],
  })),
};

export default { V3EditorialOneUpCarouselModuleData };
