/* eslint-disable no-unused-vars */
/* eslint-disable max-lines */
import { toJS } from 'mobx';

import { merge, SerializedData } from 'bernie-core';

import { getErrorString, Logger, NOOP_LOGGER, SystemEvent } from 'bernie-logger';

import { GgHomeTemplate, Sdk } from 'src/common/__generated__/goguides/types';
import { OperationResult } from 'src/common/__generated__/api';
import { SANITY_SOURCE_EVENTS } from './events/source-events';

const { withoutKeys } = merge;
export class GoGuidesApiSource {
  private readonly api: Sdk;
  private readonly apiDraft: Sdk;
  private readonly logger: Logger;

  constructor(api: Sdk, apiDraft: Sdk, logger: Logger = NOOP_LOGGER) {
    this.api = api;
    this.apiDraft = apiDraft;
    this.logger = logger;
  }

  // Get the api based on the isDraft flag;
  public getApi(isDraft): Sdk {
    if (isDraft) {
      return this.apiDraft;
    } else {
      return this.api;
    }
  }

  public ggHomeTemplate(
    variables: GgHomeTemplate.Variables,
    pageId: string,
  ): Promise<OperationResult<GgHomeTemplate.Query>> {
    const requestOptions = { 'x-page-id': pageId };

    return this.api
      .ggHomeTemplate(variables, requestOptions)
      .catch(this.getErrorHandler(variables, SANITY_SOURCE_EVENTS.SANITY_FETCH_ERROR));
  }

  private getErrorHandler(variables: any, sourceEvent: SystemEvent) {
    return (error: any) => {
      this.logger.logEvent(sourceEvent, variables, getErrorString(error));
      // Throw the error to the next catch block since it is not resolved here
      throw error;
    };
  }

  /**
   * Override the default `toJson` to exclude the Apollo client.
   * If we don't include this, we will get a JSON serialization error.
   *
   * Creates a basic JSON object from the store, excluding
   * any fields provided in `ignoreAdditionalKeys`.
   */
  public toJSON(ignoreAdditionalKeys: string[] | string = []): SerializedData {
    if (!Array.isArray(ignoreAdditionalKeys)) {
      ignoreAdditionalKeys = ignoreAdditionalKeys.constructor === String ? [ignoreAdditionalKeys] : [];
    }

    const regexIgnorePrivate = /^_.+/;
    const simpleObject: any = toJS(this);
    const ignoreKeys: string[] = Object.keys(simpleObject)
      .filter((key) => regexIgnorePrivate.test(key) || typeof simpleObject[key] === 'function')
      .concat(ignoreAdditionalKeys, 'logger', 'api', 'headers', 'apiWithFederation');

    return withoutKeys(ignoreKeys).merge({}, simpleObject);
  }
}
