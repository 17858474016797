import { AllMagazineArticle, AllOceanHotelHomeTemplate } from 'src/common/__generated__/sanity/types';
import { checkValue } from 'src/components/functions';
import { sanityLocaleMap } from '../helpers/sanityLocaleMap';
import { sanityBrandMap } from '../helpers/sanityBrandMap';
import sanityDummyData from 'src/stores/feed/sanityDummyData';
import SanityApiSourceSingleton from '../helpers/sanityApiSourceSingleton';
import { PageTheme, FeedType } from 'src/components/common/module-util';
import getPageTags from '../helpers/getTags';

export class OceanHotelService {
  private sanityApiSource = SanityApiSourceSingleton.getInstance();

  private formatSanityData({ type, data, isDummyData }: { type: 'home' | 'sub'; data: any; isDummyData: boolean }) {
    const isValidData = checkValue(data) && checkValue(data, true) && data.length > 0;
    const [oceanHotelData]: any = isValidData ? data : [];
    const templateType = type === 'home' ? 'allOceanHotelHomeTemplate' : 'allOceanHotelSubTemplate';

    if (isDummyData) {
      const newDummyData = {
        ...sanityDummyData.seo,
        content: oceanHotelData
          ? [...oceanHotelData.data[templateType], ...sanityDummyData.content]
          : sanityDummyData.content,
        pageTheme: PageTheme.BrandLight,
      };
      return newDummyData;
    } else {
      if (checkValue(oceanHotelData?.data) && checkValue(oceanHotelData?.data?.[templateType])) {
        const allOceanHotelItem = oceanHotelData?.data?.[templateType]?.[0];
        const { modules, ...rest } = allOceanHotelItem;
        if (checkValue(!allOceanHotelItem)) return [];

        const campaign = allOceanHotelItem?.pos;
        const hideHero = checkValue(allOceanHotelItem?.heroSetting?.hideHero)
          ? allOceanHotelItem?.heroSetting?.hideHero
          : false;
        const heroTags = getPageTags(allOceanHotelItem?.tags?.tag);

        const heroModule =
          checkValue(allOceanHotelItem?.heroSetting) && !hideHero
            ? {
                module: allOceanHotelItem?.heroSetting._type,
                dataFeed: FeedType.Sanity,
                moduleType: 'hero',
                ...allOceanHotelItem?.heroSetting,
                tags: heroTags,
              }
            : null;

        const formattedModules = checkValue(modules, true)
          ? allOceanHotelItem?.modules?.map((module) => ({
              module: module._type,
              ...module,
              dataFeed: FeedType.Sanity,
            }))
          : [];

        const finalFormattedData = {
          ...rest,
          ...campaign,
          content: checkValue(heroModule) ? [heroModule, ...formattedModules] : formattedModules,
          pageTheme: PageTheme.BrandLight,
          dataFeed: FeedType.Sanity,
          globalNavigationData: checkValue(campaign) ? campaign : null,
          ignoreTextTruncate: true,
        };
        return finalFormattedData;
      } else {
        return [];
      }
    }
  }

  public async fetchHomeTemplate(
    partner: string,
    templateType: string,
    isDummyData: boolean,
    pagelang: string,
    brand: string,
    isdraft: string,
  ): Promise<any> {
    const variables: AllOceanHotelHomeTemplate.Variables = {
      where: {
        pageUrl2: {
          current: {
            eq: templateType,
          },
        },
        pos: {
          brand: {
            eq: sanityBrandMap(brand),
          },
          locale: {
            eq: sanityLocaleMap(pagelang),
          },
        },
      },
    };

    const data = await this.sanityApiSource.oceanHotelRequest('home', variables, partner, isdraft);
    return this.formatSanityData({ type: 'home', data, isDummyData });
  }

  public async fetchSubTemplate(
    partner: string,
    templateType: string,
    feedName: string,
    isDummyData: boolean,
    pagelang: string,
    brand: string,
    isdraft: string,
  ): Promise<any> {
    const variables: AllMagazineArticle.Variables = {
      where: {
        pageUrl2: {
          current: {
            eq: feedName,
          },
        },
        pos: {
          brand: {
            eq: sanityBrandMap(brand),
          },
          locale: {
            eq: sanityLocaleMap(pagelang),
          },
        },
      },
    };

    const data = await this.sanityApiSource.oceanHotelRequest('sub', variables, partner, isdraft);
    return this.formatSanityData({ type: 'sub', data, isDummyData });
  }
}
