import { GraphQLClient } from 'graphql-request';
import { RequestInit as DOMRequestInit } from 'graphql-request/dist/types.dom';
import { getSdk, Sdk } from 'src/common/__generated__/sanity/types';
import { checkValue } from 'src/components/functions';
import { config } from 'src/config/config';
export interface CampaignParams {
  partner: string;
  locale: string;
  siteId: string;
  templateName?: string;
  feedName?: string;
  isCacheFeed: string;
  referrerId: string;
}

// Need to maintain two graphql clients for live and draft
// Need to create two clients for live and draft
let liveGraphqlClient: GraphQLClient | null = null;
let draftGraphqlClient: GraphQLClient | null = null;
let liveGraphqSdk: Sdk | null = null;
let draftGraphqSdk: Sdk | null = null;

const setupLiveClient = (url: string, options?: DOMRequestInit): Sdk => {  
  liveGraphqlClient = new GraphQLClient(url, options);
  return getSdk(liveGraphqlClient);
};

const setupDraftClient = (url: string, options?: DOMRequestInit): Sdk => { 
  draftGraphqlClient = new GraphQLClient(url, options);  
  return getSdk(draftGraphqlClient);
};

export const getSanityApiSdk = (
  url = config.sanityEndPoint,
  cache: RequestInit['cache'] = 'no-cache',
  headers?: HeadersInit,
  isLive: boolean = true
): Sdk => {  
  const sdkHeaders = {
    authorization: `Bearer ${config.sanityToken}`,
    ...(checkValue(headers) ? headers : {}),
  };
  const options: DOMRequestInit = {
    headers: new Headers(sdkHeaders),
    cache,
  };
 
  if (isLive) {
    if (!liveGraphqlClient || url !== config.sanityEndPoint) {
      liveGraphqSdk = setupLiveClient(config.sanityEndPoint, options);
    }
    return liveGraphqSdk;
  } else {
    if (!draftGraphqlClient || url !== config.sanityDraftEndPoint) {
      draftGraphqSdk = setupDraftClient(config.sanityDraftEndPoint, options);
    }
    return draftGraphqSdk;
  }
};