export const v3SeoFooter = {
  module: "v3seoFooter",
  moduleColor: "Primary",
  title: "Keep exploring",
  list: [
    {
      subTitle: "Africa",
      subTitleUrl: "#",
      target: "_self",
      links: [
        { title: "Seychelles", url: "#", target: "_self" },
        { title: "South Africa", url: "#", target: "_self" },
      ]
    },
    {
      subTitle: "Asia",
      subTitleUrl: "#", 
      target: "_self",
      links: [
        { title: "Cambodia", url: "#", target: "_self" },
        { title: "China", url: "#", target: "_self" },
        { title: "Hong Kong SAR", url: "#", target: "_self" },
        { title: "Laos", url: "#", target: "_self" },
        { title: "Indonesia", url: "#", target: "_self" },
        { title: "Japan", url: "#", target: "_self" },
      ]
    },
    {
      subTitle: "Europe",
      subTitleUrl: "", 
      target: "",
      links: [
        { title: "Austria Czechia", url: "#", target: "_self" },
        { title: "Belgium", url: "#", target: "_self" },
        { title: "Bosnia and Herzegovina", url: "#", target: "_self" },
        { title: "Croatia", url: "#", target: "_self" },
        { title: "Bulgaria", url: "#", target: "_self" },
        { title: "Cyprus", url: "#", target: "_self" },
        { title: "Czechia", url: "#", target: "_self" },
        { title: "Austria Czechia", url: "#", target: "_self" },
        { title: "Belgium", url: "#", target: "_self" },
        { title: "Bosnia and Herzegovina", url: "#", target: "_self" },
        { title: "Croatia", url: "#", target: "_self" },
        { title: "Bulgaria", url: "#", target: "_self" },
        { title: "Cyprus", url: "#", target: "_self" },
        { title: "Czechia", url: "#", target: "_self" },
        { title: "Austria Czechia", url: "#", target: "_self" },
        { title: "Belgium", url: "#", target: "_self" },
        { title: "Bosnia and Herzegovina", url: "#", target: "_self" },
        { title: "Croatia", url: "#", target: "_self" },
        { title: "Bulgaria", url: "#", target: "_self" },
        { title: "Cyprus", url: "#", target: "_self" },
        { title: "Czechia", url: "#", target: "_self" },
        { title: "Austria Czechia", url: "#", target: "_self" },
        { title: "Belgium", url: "#", target: "_self" },
        { title: "Bosnia and Herzegovina", url: "#", target: "_self" },
        { title: "Croatia", url: "#", target: "_self" },
        { title: "Bulgaria", url: "#", target: "_self" },
        { title: "Cyprus", url: "#", target: "_self" },
        { title: "Czechia", url: "#", target: "_self" },
        { title: "Austria Czechia", url: "#", target: "_self" },
        { title: "Belgium", url: "#", target: "_self" },
        { title: "Bosnia and Herzegovina", url: "#", target: "_self" },
        { title: "Croatia", url: "#", target: "_self" },
        { title: "Bulgaria", url: "#", target: "_self" },
        { title: "Cyprus", url: "#", target: "_self" },
        { title: "Czechia", url: "#", target: "_self" },
      ]
    },
  ],
  
  _type: "seoFooter",
};

