/* eslint-disable max-lines */
import { toJS } from 'mobx';

import { merge, SerializedData } from 'bernie-core';

import { getErrorString, Logger, NOOP_LOGGER, SystemEvent } from 'bernie-logger';

import {
  AllBatch1Template,
  AllBatch2Template,
  AllBatch3Template,
  AllBusaHomeTemplate,
  AllBusaSubTemplate,
  AllListicleBlock,
  AllMagazineArticle,
  AllMagazineHome,
  Sdk,
  AllNavigation,
  AllVrboBestTimeToBook,
  AllAllStudioTemplate,
  AllExpediaLive,
  AllOceanHotelHomeTemplate,
  AllOceanHotelSubTemplate,
} from 'src/common/__generated__/sanity/types';
import { OperationResult } from 'src/common/__generated__/api';
import { SANITY_SOURCE_EVENTS } from './events/source-events';

import apiDummyData from '../stores/feed/sampleData'; // Remove after fixed API functionality

const { withoutKeys } = merge;
export class SanityApiSource {
  private readonly api: Sdk;
  private readonly apiDraft: Sdk;
  private readonly logger: Logger;

  constructor(api: Sdk, apiDraft: Sdk, logger: Logger = NOOP_LOGGER) {
    this.api = api;
    this.apiDraft = apiDraft;
    this.logger = logger;
  }

  // Get the api based on the isDraft flag;
  public getApi(isDraft): Sdk {
    if (isDraft) {
      return this.apiDraft;
    } else {
      return this.api;
    }
  }

  public allBatch1Template(
    variables: AllBatch1Template.Variables,
    pageId: string,
  ): Promise<OperationResult<AllBatch1Template.Query>> {
    const requestOptions = { 'x-page-id': pageId };

    return this.api
      .allBatch1Template(variables, requestOptions)
      .catch(this.getErrorHandler(variables, SANITY_SOURCE_EVENTS.SANITY_FETCH_ERROR));
  }

  public allBatch2Template(
    variables: AllBatch2Template.Variables,
    pageId: string,
  ): Promise<OperationResult<AllBatch2Template.Query>> {
    const requestOptions = { 'x-page-id': pageId };

    return this.api
      .allBatch2Template(variables, requestOptions)
      .catch(this.getErrorHandler(variables, SANITY_SOURCE_EVENTS.SANITY_FETCH_ERROR));
  }

  public allBatch3Template(
    variables: AllBatch3Template.Variables,
    pageId: string,
  ): Promise<OperationResult<AllBatch3Template.Query>> {
    const requestOptions = { 'x-page-id': pageId };

    return this.api
      .allBatch3Template(variables, requestOptions)
      .catch(this.getErrorHandler(variables, SANITY_SOURCE_EVENTS.SANITY_FETCH_ERROR));
  }

  public allBusaTemplate(
    variables: AllBusaHomeTemplate.Variables,
    pageId: string,
  ): Promise<OperationResult<AllBusaHomeTemplate.Query>> {
    const requestOptions = { 'x-page-id': pageId };

    return this.api
      .allBusaHomeTemplate(variables, requestOptions)
      .catch(this.getErrorHandler(variables, SANITY_SOURCE_EVENTS.SANITY_FETCH_ERROR));
  }

  public async allBusaHomeWithListicleBlocksRequest(
    variables: AllBusaHomeTemplate.Variables,
    variablesListicleBlock: AllListicleBlock.Variables,
    pageId: string,
  ) {
    const requestOptions = { 'x-page-id': pageId };
    const batchRequests = [
      this.api.allBusaHomeTemplate(variables, requestOptions),
      this.api.allListicleBlock(variablesListicleBlock, requestOptions),
    ];

    try {
      const results = await Promise.all(batchRequests).catch(
        this.getErrorHandler(variables, SANITY_SOURCE_EVENTS.SANITY_FETCH_ERROR),
      );
      return results;
    } catch (error) {
      console.error('Error fetching batch data:', error);
      return this.getErrorHandler(variables, SANITY_SOURCE_EVENTS.SANITY_FETCH_ERROR);
    }
  }

  public async allBusaSubWithListicleFilterRequest(
    variables: AllBusaSubTemplate.Variables,
    variablesListicleBlock: AllListicleBlock.Variables,
    pageId: string,
  ) {
    const requestOptions = { 'x-page-id': pageId };
    const batchRequests = [
      this.api.allBusaSubTemplate(variables, requestOptions),
      this.api.allListicleBlock(variablesListicleBlock, requestOptions),
    ];

    try {
      const results = await Promise.all(batchRequests).catch(
        this.getErrorHandler(variables, SANITY_SOURCE_EVENTS.SANITY_FETCH_ERROR),
      );

      return results;
    } catch (error) {
      console.error('Error fetching batch data:', error);
      return this.getErrorHandler(variables, SANITY_SOURCE_EVENTS.SANITY_FETCH_ERROR);
    }
  }

  public allBusaSubTemplate(
    variables: AllBusaSubTemplate.Variables,
    pageId: string,
  ): Promise<OperationResult<AllBusaSubTemplate.Query>> {
    const requestOptions = { 'x-page-id': pageId };

    return this.api
      .allBusaSubTemplate(variables, requestOptions)
      .catch(this.getErrorHandler(variables, SANITY_SOURCE_EVENTS.SANITY_FETCH_ERROR));
  }

  public allMagazineHome(
    variables: AllMagazineHome.Variables,
    pageId: string,
  ): Promise<OperationResult<AllMagazineHome.Query>> {
    const requestOptions = { 'x-page-id': pageId };

    return this.api
      .allMagazineHome(variables, requestOptions)
      .catch(this.getErrorHandler(variables, SANITY_SOURCE_EVENTS.SANITY_FETCH_ERROR));
  }

  public async allMagazineHomeWithNaviagationRequest(
    variables: AllMagazineHome.Variables,
    pageId: string,
    isdraft?: string,
  ) {
    const requestOptions = { 'x-page-id': pageId };

    // Base on the isdraft flag, get the api
    const api = isdraft === '1' ? true : false;
    const getApi = this.getApi(api);

    const batchRequests = [getApi.allMagazineHome(variables, requestOptions)];

    try {
      const results = await Promise.all(batchRequests).catch(
        this.getErrorHandler(variables, SANITY_SOURCE_EVENTS.SANITY_FETCH_ERROR),
      );
      return results;
    } catch (error) {
      console.error('Error fetching batch data:', error);
      return this.getErrorHandler(variables, SANITY_SOURCE_EVENTS.SANITY_FETCH_ERROR);
    }
  }

  public allMagazineArticle(
    variables: AllMagazineArticle.Variables,
    pageId: string,
  ): Promise<OperationResult<AllMagazineArticle.Query>> {
    const requestOptions = { 'x-page-id': pageId };

    return this.api
      .allMagazineArticle(variables, requestOptions)
      .catch(this.getErrorHandler(variables, SANITY_SOURCE_EVENTS.SANITY_FETCH_ERROR));
  }

  public async allVrboBestTimeToBookWithNaviagationRequest(
    variables: AllVrboBestTimeToBook.Variables,
    navigationVariables: AllNavigation.Variables,
    pageId: string,
  ) {
    const requestOptions = { 'x-page-id': pageId };
    const batchRequests = [
      this.api.allVrboBestTimeToBook(variables, requestOptions),
      this.api.allNavigation(navigationVariables, requestOptions),
    ];

    try {
      const results = await Promise.all(batchRequests).catch(
        this.getErrorHandler(variables, SANITY_SOURCE_EVENTS.SANITY_FETCH_ERROR),
      );
      return results;
    } catch (error) {
      console.error('Error fetching batch data:', error);
      return this.getErrorHandler(variables, SANITY_SOURCE_EVENTS.SANITY_FETCH_ERROR);
    }
  }

  public async allMagazineArticleWithNaviagationRequest(
    variables: AllMagazineArticle.Variables,
    pageId: string,
    isdraft?: string,
  ) {
    const requestOptions = { 'x-page-id': pageId };

    // Base on the isdraft flag, get the api
    const api = isdraft === '1' ? true : false;
    const getApi = this.getApi(api);

    const batchRequests = [getApi.allMagazineArticle(variables, requestOptions)];

    try {
      const results = await Promise.all(batchRequests).catch(
        this.getErrorHandler(variables, SANITY_SOURCE_EVENTS.SANITY_FETCH_ERROR),
      );
      return results;
    } catch (error) {
      console.error('Error fetching batch data:', error);
      return this.getErrorHandler(variables, SANITY_SOURCE_EVENTS.SANITY_FETCH_ERROR);
    }
  }

  public allStudioTemplateRequest(
    variables: AllAllStudioTemplate.Variables,
    pageId: string,
  ): Promise<OperationResult<AllAllStudioTemplate.Query>> {
    const requestOptions = { 'x-page-id': pageId };

    return this.api
      .allAllStudioTemplate(variables, requestOptions)
      .catch(this.getErrorHandler(variables, SANITY_SOURCE_EVENTS.SANITY_FETCH_ERROR));
  }

  private getErrorHandler(variables: any, sourceEvent: SystemEvent) {
    return (error: any) => {
      this.logger.logEvent(sourceEvent, variables, getErrorString(error));
      // Throw the error to the next catch block since it is not resolved here
      throw error;
    };
  }

  /**
   * Override the default `toJson` to exclude the Apollo client.
   * If we don't include this, we will get a JSON serialization error.
   *
   * Creates a basic JSON object from the store, excluding
   * any fields provided in `ignoreAdditionalKeys`.
   */
  public toJSON(ignoreAdditionalKeys: string[] | string = []): SerializedData {
    if (!Array.isArray(ignoreAdditionalKeys)) {
      ignoreAdditionalKeys = ignoreAdditionalKeys.constructor === String ? [ignoreAdditionalKeys] : [];
    }

    const regexIgnorePrivate = /^_.+/;
    const simpleObject: any = toJS(this);
    const ignoreKeys: string[] = Object.keys(simpleObject)
      .filter((key) => regexIgnorePrivate.test(key) || typeof simpleObject[key] === 'function')
      .concat(ignoreAdditionalKeys, 'logger', 'api', 'headers', 'apiWithFederation');

    return withoutKeys(ignoreKeys).merge({}, simpleObject);
  }

  public allExpediaLive(
    variables: AllExpediaLive.Variables,
    pageId: string,
    isdraft?: string,
  ): Promise<OperationResult<AllExpediaLive.Query>> {
    const requestOptions = { 'x-page-id': pageId };

    // Base on the isdraft flag, get the api
    const api = isdraft === '1' ? true : false;
    const getApi = this.getApi(api);

    return getApi
      .allExpediaLive(variables, requestOptions)
      .catch(this.getErrorHandler(variables, SANITY_SOURCE_EVENTS.SANITY_FETCH_ERROR));
  }

  public async allExpediaLiveWithNaviagationRequest(
    variables: AllExpediaLive.Variables,
    pageId: string,
    isdraft?: string,
  ): Promise<OperationResult<AllExpediaLive.Query>> {
    const requestOptions = { 'x-page-id': pageId };

    // Base on the isdraft flag, get the api
    const api = isdraft === '1' ? true : false;
    const getApi = this.getApi(api);

    return getApi
      .allExpediaLive(variables, requestOptions)
      .catch(this.getErrorHandler(variables, SANITY_SOURCE_EVENTS.SANITY_FETCH_ERROR));
  }

  public async fetchCapsAPIData(callNumber: number): Promise<any> {
    try {
      const url = `https://www.expedia.com/capsapi/sanity/Exp/local-lens-ewan-mcgregor-guide-scotland/en-us?cursor=${callNumber}`;

      const response = await fetch(url, { method: 'GET' });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      return { error: error.message || "An unexpected error occurred" };
    }
  }

  public async oceanHotelRequest(
    type: 'home' | 'sub',
    variables: AllOceanHotelHomeTemplate.Variables | AllOceanHotelSubTemplate.Variables,
    pageId: string,
    isdraft?: string,
  ) {
    const requestOptions = { 'x-page-id': pageId };
    const api = isdraft === '1';
    const getApi = this.getApi(api);

    const apiMethod = type === 'home' ? getApi.allOceanHotelHomeTemplate : getApi.allOceanHotelSubTemplate;
    const batchRequests = [apiMethod(variables, requestOptions)];

    try {
      const results = await Promise.all(batchRequests).catch(
        this.getErrorHandler(variables, SANITY_SOURCE_EVENTS.SANITY_FETCH_ERROR),
      );
      return results;
    } catch (error) {
      console.error('Error fetching batch data:', error);
      return this.getErrorHandler(variables, SANITY_SOURCE_EVENTS.SANITY_FETCH_ERROR);
    }
  }
}
