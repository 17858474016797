import { AllExpediaLive } from 'src/common/__generated__/sanity/types';
import { checkValue } from 'src/components/functions';
import { sanityBrandMap } from '../helpers/sanityBrandMap';
import { sanityLocaleMap } from '../helpers/sanityLocaleMap';
import sanityDummyData from 'src/stores/feed/sanityDummyData';
import SanityApiSourceSingleton from '../helpers/sanityApiSourceSingleton';
import { PageTheme, FeedType } from 'src/components/common/module-util';
import { updateAppDownloadModulePosition } from '../helpers/updateAppDownloadModulePosition';
import getPageTags from '../helpers/getTags';

export class ExpediaLiveTemplateService {
  private sanityApiSource = SanityApiSourceSingleton.getInstance();

  public async featchExpediaLiveHome(
    partner: string,
    templateName: string,
    isDummyData: boolean,
    pagelang: string,
    brand: string,
    isdraft: string,
  ): Promise<any> {
    const variables: AllExpediaLive.Variables = {
      where: {
        pageUrl2: {
          current: {
            eq: templateName,
          },
        },
        pos: {
          brand: {
            eq: sanityBrandMap(brand),
          },
          locale: {
            eq: sanityLocaleMap(pagelang),
          },
        },
      },
    };
    
    const data = await this.sanityApiSource.allExpediaLiveWithNaviagationRequest(variables, partner, isdraft);
    const isValidData = checkValue(data) && checkValue(data?.data) && checkValue(data?.data?.allExpediaLive) && data?.data?.allExpediaLive.length > 0;   
    const allExpediaLive = isValidData ? data?.data?.allExpediaLive : [];

    if (isDummyData) {
      const newDummyData = {
        ...sanityDummyData.seo,
        content: allExpediaLive
          ? [...allExpediaLive, ...sanityDummyData.content]
          : sanityDummyData.content,
        pageTheme: allExpediaLive,
      };
      return newDummyData;
    } else {
      if (isValidData) {
        const allExpediaLiveItem = allExpediaLive?.[0];
        
        if (checkValue(!allExpediaLiveItem)) return [];

        const campaign = allExpediaLiveItem?.pos;
        const heroTags = getPageTags(allExpediaLiveItem?.tags?.tag);
        const { heroSetting, modules, ...rest } = allExpediaLiveItem;

        const heroModule = checkValue(heroSetting)
          ? {
              module: heroSetting._type,
              dataFeed: FeedType.Sanity,
              moduleType: 'hero',
              ...heroSetting,
              tags: heroTags,
            }
          : null;

        const formattedModules = modules?.map((module) => ({
          module: module?.['_type'],
          ...module,
          dataFeed: FeedType.Sanity,
        }));

        const finalFormattedModules = updateAppDownloadModulePosition(
          formattedModules,
          allExpediaLiveItem?.tags?.appModule,
          campaign,
        );

        const finalFormattedData = {
          ...rest,
          ...campaign,
          content: checkValue(heroModule) ? [heroModule, ...finalFormattedModules] : finalFormattedModules,
          pageTheme: PageTheme.BrandLight,
          dataFeed: FeedType.Sanity,
          globalNavigationData: checkValue(campaign) ? campaign : null,
          ignoreTextTruncate: true,
        };

        return finalFormattedData;
      } else {
        return [];
      }
    }
  }
}
