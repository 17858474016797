import { Request } from 'bernie-http';
import { checkValue } from 'src/components/functions';

export const sanityUrls = [
  // Visit USA
  { campaignPartner: 'see', campaignTemplate: 'visitUSA', BatchQueue: 'busa', },
  // Magazine
  { campaignPartner: 'see', campaignTemplate: 'magazine', BatchQueue: 'magazine', },
  // ***** BTB/VROY pages run on the Magazine Article Template **** //
  // Best Time
  { campaignPartner: 'see', campaignTemplate: 'best-time-to-book-en-us', BatchQueue: 'btb', },
  { campaignPartner: 'see', campaignTemplate: 'best-time-to-book-en-gb', BatchQueue: 'btb', },
  { campaignPartner: 'see', campaignTemplate: 'best-time-to-book-en-au', BatchQueue: 'btb', },
  { campaignPartner: 'see', campaignTemplate: 'meilleurs-moments-pour-reserver', BatchQueue: 'btb', },
  { campaignPartner: 'see', campaignTemplate: 'beste-zeit-zu-buchen', BatchQueue: 'btb', },
  // Vacation Rentals
  { campaignPartner: 'see', campaignTemplate: 'vacation-rentals-of-the-year', BatchQueue: 'vroy', },
  { campaignPartner: 'see', campaignTemplate: 'vacation-rentals-of-the-year-ca', BatchQueue: 'vroy', },
  { campaignPartner: 'see', campaignTemplate: 'proprietes-de-vacances-de-l-annee', BatchQueue: 'vroy', },
  { campaignPartner: 'see', campaignTemplate: 'holiday-homes-of-the-year', BatchQueue: 'vroy', },
  { campaignPartner: 'see', campaignTemplate: 'holiday-homes-of-the-year-au', BatchQueue: 'vroy', },
  { campaignPartner: 'see', campaignTemplate: 'holiday-homes-of-the-year-nz', BatchQueue: 'vroy', },
  { campaignPartner: 'see', campaignTemplate: 'locations-de-vacances-de-l-annee', BatchQueue: 'vroy', },
  { campaignPartner: 'see', campaignTemplate: 'ferienhaeuser-des-jahres', BatchQueue: 'vroy', },
  // Ocean Hotels
  { campaignPartner: 'see', campaignTemplate: 'oceanhotels', BatchQueue: 'oceanhotel', },
];

const checkIsSanityData = (templateName) => {
  // Magazine Template
  // magazine --> /magazine"  | /tempmagazine | see/magazine | see/tempmagazine
  // magazine --> /magazine/abc" | /tempmagazine/abc"
  if (templateName?.toLowerCase().startsWith('magazine')) {
    return [
      {
        campaignPartner: 'see',
        campaignTemplate: 'magazine',
        BatchQueue: 'magazine',
      },
    ];
  }

  const isByPassSanity = sanityUrls.filter((u) => {
    return u?.campaignTemplate?.toLowerCase() === templateName.toLowerCase();
  });

  return isByPassSanity;
};

const checkIsGoogleAnalytics = (templateName, feedName) => {
  let isEnable = true;
  const btb = sanityUrls.filter((u) => u.BatchQueue === 'btb').map((u) => u.campaignTemplate);

  if (templateName === 'magazine' && feedName === '') {
    isEnable = false;
  }

  if (templateName === "magazine" && checkValue(feedName) && !btb.includes(feedName)) {
    isEnable = false;
  }

  return isEnable;
};

export const routeMapper = (partner, templateName, feedName, query: Request['query']) => {
  const { dummyData, batchQuery, filter, sanity } = query || {};

  const isByPassSanity = checkIsSanityData(templateName);
  const isSanityData = sanity === '1' || checkValue(isByPassSanity, true);

  const isSanityDummyData = isSanityData && dummyData === '1';
  const finalBatchQuery = checkValue(isByPassSanity, true)
    ? isByPassSanity?.[0]?.BatchQueue
    : checkValue(batchQuery)
      ? batchQuery
      : 'b1';
  const finalBusaFilter = filter ?? '';

  return {
    isSanityData,
    isSanityDummyData,
    batchQuery: finalBatchQuery,
    busaFilter: finalBusaFilter,
    isEnabledGoogleAnalytics: checkIsGoogleAnalytics(templateName, feedName)
  };
};

// BTB/VROY pages run on the Magazine Template 
// We do not need to show the "More Stories" modules on these pages
export const isNotBtbUrl = (campaignTemplate) => {
  const btbTemplates = sanityUrls
    .filter(({ BatchQueue }) => ['btb', 'vroy'].includes(BatchQueue))
    .map(({ campaignTemplate }) => campaignTemplate);

  return !btbTemplates.includes(campaignTemplate);
};
