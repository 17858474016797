/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { UitkPrimaryButton, UitkSecondaryButton, UitkButtonSize, UitkTertiaryButton } from 'uitk-react-button';
import { UitkIcon, UitkIconProps } from 'uitk-react-icons';
import gaLogger from 'src/utils/ga-logger';
import { appendMcicid, checkValue } from 'src/components/common/helper';
import { sendGTMDatalayerEvent } from '../../utils/gtmHelperFunctions';

export interface PrimaryButtonProps {
  text: string;
  exClass?: string;
  styles?: React.CSSProperties;
  isFullWidth?: boolean;
  url?: string;
  target?: '' | '_blank' | string;
  tag?: 'a' | 'button';
  onClickEvent?: (evt: any, exParam?: any) => any;
  expTracking?:
    | {
        templateType: string;
        campaignUrl: string;
        mainUrl: string;
        referrerId: string;
      }
    | undefined;
  gaTracking:
    | {
        eventCategory: string;
        eventAction: string;
        eventLabel: string;
      }
    | undefined;
  icon?: UitkIconProps;
  size?: UitkButtonSize;
  iconPos?: 'BEFORE_TEXT' | 'AFTER_TEXT';
  disabled?: boolean;
  tabindex?: number;
}

export const PrimaryButton: React.FC<PrimaryButtonProps> = (props) => {
  const {
    text,
    exClass = '',
    styles,
    isFullWidth = false,
    tag = 'button',
    url,
    target = '',
    onClickEvent,
    icon,
    size = UitkButtonSize.MEDIUM,
    iconPos = 'AFTER_TEXT',
    disabled = false,
    tabindex = 0,
  } = props;

  const buttonClickEvent = (e) => {
    e.stopPropagation();
    if (onClickEvent) {
      onClickEvent(e);
    }

    const { gaTracking, expTracking } = props;
    if (gaTracking) {
      const { eventCategory, eventAction, eventLabel } = gaTracking;
      gaLogger.logGa(eventCategory, eventAction, eventLabel);
      sendGTMDatalayerEvent('button_click', {
        Button_Text: text,
        URL: url,
        module_name: eventCategory + ' - ' + eventAction,
      });
    }
    if (url) {
      let urlWithTracking = url;
      if (expTracking && checkValue(expTracking.templateType)) {
        const { templateType, campaignUrl, mainUrl, referrerId = '' } = expTracking;
        urlWithTracking = appendMcicid(url, templateType, campaignUrl, mainUrl, referrerId);
      }

      if (target === '') {
        window.location.href = urlWithTracking;
      } else {
        window.open(urlWithTracking, target);
      }
    }
  };

  return (
    <UitkPrimaryButton
      type={tag === 'button' ? 'submit' : null}
      tag={tag}
      className={exClass}
      isFullWidth={isFullWidth}
      onClick={(e) => buttonClickEvent(e)}
      style={styles}
      size={size}
      disabled={disabled}
    >
      {icon && iconPos === 'BEFORE_TEXT' && <UitkIcon name={icon?.name} className={icon?.className} />}
      <span>{text}</span>
      {icon && iconPos === 'AFTER_TEXT' && <UitkIcon name={icon?.name} className={icon?.className} />}
    </UitkPrimaryButton>
  );
};

export interface SecondaryButtonProps {
  text: string;
  exClass?: string;
  styles?: React.CSSProperties;
  isFullWidth?: boolean;
  url?: string;
  target?: '' | '_blank' | string;
  tag?: 'a' | 'button';
  onClickEvent?: (evt: any, exParam?: any) => any;
  expTracking?:
    | {
        templateType: string;
        campaignUrl: string;
        mainUrl: string;
        referrerId: string;
      }
    | undefined;
  gaTracking:
    | {
        eventCategory: string;
        eventAction: string;
        eventLabel: string;
      }
    | undefined;
  icon?: UitkIconProps;
  size?: UitkButtonSize;
}

export const SecondaryButton: React.FC<SecondaryButtonProps> = (props) => {
  const {
    text,
    exClass = '',
    styles,
    isFullWidth = false,
    tag = 'button',
    url,
    target = '',
    onClickEvent,
    icon,
  } = props;
  const buttonClickEvent = (e) => {
    e.stopPropagation();
    if (onClickEvent) {
      onClickEvent(e);
    }

    const { gaTracking, expTracking } = props;
    if (gaTracking) {
      const { eventCategory, eventAction, eventLabel } = gaTracking;
      gaLogger.logGa(eventCategory, eventAction, eventLabel);
      sendGTMDatalayerEvent('button_click', {
        Button_Text: text,
        URL: url,
        module_name: eventCategory + ' - ' + eventAction,
      });
    }
    if (url) {
      let urlWithTracking = url;
      if (expTracking) {
        const { templateType, campaignUrl, mainUrl, referrerId = '' } = expTracking;
        urlWithTracking = appendMcicid(url, templateType, campaignUrl, mainUrl, referrerId);
      }

      if (target === '') {
        window.location.href = url;
      } else {
        window.open(url, target);
      }
    }
  };
  return (
    <UitkSecondaryButton
      tag={tag}
      className={exClass}
      isFullWidth={isFullWidth}
      onClick={(e) => buttonClickEvent(e)}
      style={styles}
      tabIndex={0}
      aria-label={text}
    >
      <span>{text}</span>
      {icon && <UitkIcon name={icon.name} className={icon.className} />}
    </UitkSecondaryButton>
  );
};

export interface TertiaryButtonProps {
  text: string;
  exClass?: string;
  styles?: React.CSSProperties;
  isFullWidth?: boolean;
  url?: string;
  target?: '' | '_blank' | string;
  tag?: 'a' | 'button';
  onClickEvent?: (evt?: any) => any | undefined;
  expTracking?:
    | {
        templateType: string;
        campaignUrl: string;
        mainUrl: string;
        referrerId: string;
      }
    | undefined;
  gaTracking:
    | {
        eventCategory: string;
        eventAction: string;
        eventLabel: string;
      }
    | undefined;
  icon?: UitkIconProps;
  size?: UitkButtonSize;
  alignLabelToContent?: 'end' | 'start';
}

export const TertiaryButton: React.FC<TertiaryButtonProps> = (props) => {
  const {
    text,
    exClass = '',
    styles,
    isFullWidth = false,
    tag = 'button',
    url,
    target = '',
    onClickEvent,
    icon,
    alignLabelToContent,
  } = props;
  const buttonClickEvent = (e) => {
    e.stopPropagation();
    if (onClickEvent) {
      onClickEvent(e);
    }

    const { gaTracking, expTracking } = props;
    if (gaTracking) {
      const { eventCategory, eventAction, eventLabel } = gaTracking;
      gaLogger.logGa(eventCategory, eventAction, eventLabel);
      sendGTMDatalayerEvent('button_click', {
        Button_Text: text,
        URL: url,
        module_name: eventCategory + ' - ' + eventAction,
      });
    }
    if (url) {
      let urlWithTracking = url;
      if (expTracking) {
        const { templateType, campaignUrl, mainUrl, referrerId = '' } = expTracking;
        urlWithTracking = appendMcicid(url, templateType, campaignUrl, mainUrl, referrerId);
      }

      if (target === '') {
        window.location.href = url;
      } else {
        window.open(url, target);
      }
    }
  };
  return (
    <UitkTertiaryButton
      type={tag === 'button' ? 'submit' : null}
      tag={tag}
      className={exClass}
      isFullWidth={isFullWidth}
      onClick={(e) => buttonClickEvent(e)}
      style={styles}
      alignLabelToContent={alignLabelToContent}
    >
      <span>{text}</span>
      {icon && <UitkIcon name={icon.name} className={icon.className} />}
    </UitkTertiaryButton>
  );
};
