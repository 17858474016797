export const en_us = {
  validation_error: 'This field is required.',
  all: 'All',
  filter_result_text: 'SHOWING',
  filter_msg_title: 'No results found.',
  filter_msg: "We couldn't find what you searching for. Try searching again.",
  slick_prev_button_aria_label: 'Go to the previous slide in the carousel',
  slick_next_button_aria_label: 'Go to the next slide in the carousel',
  slick_dots_aria_label: 'Slide ${1} of ${2}',
};
