import { AllMagazineArticle, AllMagazineHome, AllNavigation } from 'src/common/__generated__/sanity/types';
import { checkValue } from 'src/components/functions';
import { sanityLocaleMap } from '../helpers/sanityLocaleMap';
import { sanityBrandMap } from '../helpers/sanityBrandMap';
import sanityDummyData from 'src/stores/feed/sanityDummyData';
import SanityApiSourceSingleton from '../helpers/sanityApiSourceSingleton';
import { PageTheme, FeedType } from 'src/components/common/module-util';
import { updateAppDownloadModulePosition } from '../helpers/updateAppDownloadModulePosition';
import getPageTags from '../helpers/getTags';
import { isNotBtbUrl } from 'src/controllers/football/route-mapper';

export class MagazineTemplateService {
  private sanityApiSource = SanityApiSourceSingleton.getInstance();

  public async fetchMagazineHomeTemplate(
    partner: string,
    templateName: string,
    isDummyData: boolean,
    pagelang: string,
    brand: string,
    isdraft: string,
  ): Promise<any> {
    const variables: AllMagazineHome.Variables = {
      where: {
        pageUrl2: {
          current: {
            eq: templateName,
          },
        },
        pos: {
          brand: {
            eq: sanityBrandMap(brand),
          },
          locale: {
            eq: sanityLocaleMap(pagelang),
          },
        },
      },
    };

    const data = await this.sanityApiSource.allMagazineHomeWithNaviagationRequest(variables, partner, isdraft);
    const isValidData = checkValue(data) && checkValue(data, true) && data.length > 0;
    const [magazineData]: any = isValidData ? data : [];

    if (isDummyData) {
      const newDummyData = {
        ...sanityDummyData.seo,
        content: magazineData
          ? [...magazineData.data.allMagazineHome, ...sanityDummyData.content]
          : sanityDummyData.content,
        pageTheme: PageTheme.BrandLight,
      };
      return newDummyData;
    } else {
      if (checkValue(magazineData?.data) && checkValue(magazineData?.data?.allMagazineHome)) {
        const allMagazineItem = magazineData?.data?.allMagazineHome?.[0];

        if (checkValue(!allMagazineItem)) return [];

        const campaign = allMagazineItem?.pos;
        const heroTags = getPageTags(allMagazineItem?.tags?.tag);

        const {
          heroes = [],
          featuredContent = [],
          dynamicContent = [],
          conversationBreaker = [],
          heroSetting,
          ...rest
        } = allMagazineItem;

        const heroModule = checkValue(heroSetting)
          ? {
              module: heroSetting._type,
              dataFeed: FeedType.Sanity,
              moduleType: 'hero',
              ...heroSetting,
              tags: heroTags,
            }
          : null;

        const heroModules = checkValue(heroes, true)
          ? heroes.map((hero) => {
              if (hero._type === 'articleHero') {
                return {
                  ...hero,
                  tags: heroTags,
                };
              } else {
                return { ...hero };
              }
            })
          : [];

        const formattedModules = [
          ...heroModules,
          ...(featuredContent ?? []),
          ...(conversationBreaker ?? []),
          ...(dynamicContent ?? []),
        ]?.map((module) => ({
          module: module?.['_type'],
          ...module,
          // ...(module?.['_type'] === 'liveEventHero' ? { tags: heroTags } : {}),
          dataFeed: FeedType.Sanity,
        }));

        const finalFormattedModules = updateAppDownloadModulePosition(
          formattedModules,
          allMagazineItem?.tags?.appModule,
          campaign,
        );

        // const heroModule = checkValue(allMagazineItem?.heroSetting)
        //   ? { module: allMagazineItem?.heroSetting._type, dataFeed: 'sanity', ...allMagazineItem?.heroSetting }
        //   : null;
        const finalFormattedData = {
          ...rest,
          ...campaign,
          content: checkValue(heroModule) ? [heroModule, ...finalFormattedModules] : finalFormattedModules,
          // content: formattedModules,
          pageTheme: PageTheme.BrandLight,
          dataFeed: FeedType.Sanity,
          globalNavigationData: checkValue(campaign) ? campaign : null,
          ignoreTextTruncate: true,
        };
        return finalFormattedData;
      } else {
        return [];
      }
    }
  }

  public async fetchMagazineArticleTemplate(
    partner: string,
    templateName: string,
    feedName: string,
    isDummyData: boolean,
    pagelang: string,
    brand: string,
    isdraft: string,
  ): Promise<any> {
    const variables: AllMagazineArticle.Variables = {
      where: {
        pageUrl2: {
          current: {
            eq: feedName,
          },
        },
        pos: {
          brand: {
            eq: sanityBrandMap(brand),
          },
          locale: {
            eq: sanityLocaleMap(pagelang),
          },
        },
      },
    };

    const data = await this.sanityApiSource.allMagazineArticleWithNaviagationRequest(variables, partner, isdraft);

    const isValidData = checkValue(data) && checkValue(data, true) && data.length > 0;
    const [magazinArticleData]: any = isValidData ? data : [];

    if (isDummyData) {
      const newDummyData = {
        ...sanityDummyData.seo,
        content: magazinArticleData
          ? [...magazinArticleData.allMagazineArticle, ...sanityDummyData.content]
          : sanityDummyData.content,
        pageTheme: PageTheme.BrandLight,
      };
      return newDummyData;
    } else {
      if (checkValue(magazinArticleData?.data) && checkValue(magazinArticleData?.data?.allMagazineArticle)) {
        const allMagazineItem = magazinArticleData?.data?.allMagazineArticle?.[0];
        const campaign = allMagazineItem?.pos;
        const heroTags = getPageTags(allMagazineItem?.tags?.tag);

        if (checkValue(!allMagazineItem)) return [];

        const {
          group1,
          group2,
          group3,
          group4,
          heroChoice,
          heroSetting,
          sponsorHeroSettings,
          categoryLandingHeroSettings,
          ...rest
        } = allMagazineItem;

        const hideMoreStories = allMagazineItem?.hideMoreStories ?? false;        

        let heroModule = null;

        if (checkValue(heroChoice) && heroChoice === 'Sponsored Hero') {
          heroModule = checkValue(sponsorHeroSettings)
            ? {
                module: sponsorHeroSettings._type,
                dataFeed: FeedType.Sanity,
                moduleType: 'hero',
                ...sponsorHeroSettings,
                tags: heroTags,
              }
            : null;
        } else if (checkValue(heroChoice) && heroChoice === 'Category Landing Hero') {
          heroModule = checkValue(categoryLandingHeroSettings)
            ? {
                module: categoryLandingHeroSettings._type,
                dataFeed: FeedType.Sanity,
                moduleType: 'hero',
                ...categoryLandingHeroSettings,
                tags: heroTags,
              }
            : null;
        } else {
          heroModule = checkValue(heroSetting)
            ? {
                module: heroSetting._type,
                dataFeed: FeedType.Sanity,
                moduleType: 'hero',
                ...heroSetting,
                tags: heroTags,
              }
            : null;
        }

        const formattedModules = [...(group1 ?? []), ...(group2 ?? []), ...(group3 ?? []), ...(group4 ?? [])]?.map(
          (module) => ({
            module: module?.['_type'],
            ...module,
            // ...(module?.['_type'] === 'liveEventHero' ? { tags: heroTags } : {}),
            dataFeed: FeedType.Sanity,
          }),
        );

        const finalFormattedModules = updateAppDownloadModulePosition(
          formattedModules,
          allMagazineItem?.tags?.appModule,
          campaign,
        );

        // const heroModule = checkValue(allMagazineItem?.heroSetting)
        //   ? { module: allMagazineItem?.heroSetting._type, dataFeed: 'sanity', ...allMagazineItem?.heroSetting }
        //   : null;
        const finalFormattedData = {
          ...rest,
          ...campaign,
          content: checkValue(heroModule) ? [heroModule, ...finalFormattedModules] : finalFormattedModules,
          // content: formattedModules,
          isArticlePage: !hideMoreStories && isNotBtbUrl(feedName),
          pageTheme: PageTheme.BrandLight,
          dataFeed: FeedType.Sanity,
          globalNavigationData: checkValue(campaign) ? campaign : null,
          ignoreTextTruncate: true,
        };
        return finalFormattedData;
      } else {
        return [];
      }
    }
  }
}
